@import "/src/styles/mixin";

.wrap {
  width: calc(100vw - 80px);
  max-width: 600px;
}

.rate {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
  transition: .25s;
  &Text {
    @include font14;
  }
}



.form {
  max-width: 582px;
  width: 100%;
}

.textarea {
  @include font14;
  width: 100%;
  padding: 14px 20px;
  border: 1px solid var(--grey-color);
  border-radius: var(--border-radius-small);
  resize: none;
  margin-bottom: 20px;

  &:focus {
    outline: none;
    border-color: var(--secondary-color);
  }

  &.is-not-valid {
    border-color: var(--red-color);
    animation: 0.1s tremor ease-out 1;
  }

  &:hover {
    border-color: var(--secondary-color);
  }
}

.button {
  padding: 14px 26px;

  &Text {
    @include font14;
  }
}

.clickedRate {
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 6px;
  height: 28px;

  .rateCheck {
    font-size: 30px;
    transition: .15s;
    background-color: transparent;
    border: none;
  }

  .active {
    color: var(--primary-color);
  }

  .noActive {
    color: var(--grey-lines-color);
  }
}