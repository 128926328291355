@import "/src/styles/mixin";

.button {
  @include font14Bold;
  display: flex;
  align-items: center;
  gap: 8px;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: none;
  }
}
