@import "/src/styles/mixin";

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0 8px 8px;
    transition: .25s;
    border-radius: var(--border-radius-small);
    align-items: center;

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      .image {
        width: 58px;
        max-width: 58px;
        height: 32px;
        object-fit: contain;
      }

      .name {
        @include font14;
        letter-spacing: 0.01em;
      }
    }

    &:hover {
      background-color: var(--blue-background-color);
    }

    .chevron {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 46px;
      width: 16px;
      height: 16px;
      transform: rotate(-90deg);

      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }
}