@import "src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey-lines-color);

  padding: 0 0 4px 0;

  .left_side {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .image {
      width: 48px;
      height: 48px;
      object-fit: contain;

      @media screen and (max-width: 425px) {
        display: none;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 2px;

      .name {
        @include font14;
        // max-width: 382px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;

        @media screen and (max-width: 1440px) and (min-width: 1034px) {
          max-width: 300px;
        }

        @media screen and (max-width: 1033px) and (min-width: 768px) {
          max-width: 250px;
        }

        @media screen and (max-width: 425px) {
          -webkit-line-clamp: 2;
          line-clamp: 2;
        }
      }
    }

    @media screen and (max-width: 425px) {
      margin-right: 8px;
    }
  }

  .right_side {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .prices {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .price {
        @include font14;
        letter-spacing: 0.01em;
        font-weight: 800;
        padding: 3px 4px;
        background-color: var(--blue-background-color);
        border-radius: 4px;
        white-space: nowrap;

        &Old {
          @include font13;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.01em;
          margin-left: 4px;
          white-space: nowrap;
        }
      }
    }

    .chevron {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 46px;
      width: 16px;
      height: 16px;
      transform: rotate(-90deg);

      @media screen and (max-width: 1444px) {
        // display: none;
        min-width: 16px;
        max-width: 16px;
      }

    }
  }

  &:last-child {
    border-bottom: none;
  }
}


// .wrap {
//   display: flex;
//   flex-direction: column;
//   gap: 17px;
//   background-color: var(--white-color);
//   border: 1px solid var(--grey-lines-color);
//   border-radius: var(--border-radius-normal);
//   padding: 25px 25px;

//   .head {
//     padding-top: 28px;
//     position: relative;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;

//     .image {
//       width: 100%;
//       height: auto;
//       mix-blend-mode: multiply;
//       object-fit: contain;
//       aspect-ratio: 1 / 1;
//     }
//   }

//   .info {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     gap: 8px;

//     .name {
//       @include font14;
//       letter-spacing: 0.01em;
//       height: 48px;
//       display: -webkit-box;
//       -webkit-line-clamp: 2;
//       -webkit-box-orient: vertical;
//       color: var(--secondary-color);
//       overflow: hidden;
//     }
//   }

//   .bottom {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     width: 100%;
//     align-items: center;

//     .prices {
//       display: flex;
//       flex-direction: column;
//       gap: 4px;

//       .price {
//         @include font14;
//         letter-spacing: 0.01em;
//         font-weight: 700;
//         padding: 4px 8px;
//         background-color: var(--blue-background-color);
//         border-radius: 4px;

//         &Old {
//           @include font14;
//           letter-spacing: 0.01em;
//           text-decoration-line: line-through;
//         }
//       }
//     }

//     .actions {
//       display: flex;
//       flex-direction: row;
//       gap: 8px;
//     }
//   }
// }