@import "/src/styles/mixin";

.button {
  @include font14Bold;
  display: block;
  text-align: center;
  width: 100%;
  padding: 16px 26px;
  border-radius: var(--border-radius-small);
  border: 2px solid var(--secondary-color);
  transition: border-color 0.2s ease;
  &:hover {
    border-color: var(--primary-color);
  }
}
